import { useState, useEffect, FC, useContext } from 'react';
import Link from 'next/link';
import NavbarLinks from './NavbarLinks';
import NavbarSearch from './NavbarSearch';
import WarningRounded from '@mui/icons-material/WarningRounded';
import { useRouter } from 'next/router';
import styles from 'styles/components/Navbar.module.scss';
import usePaymentDetails from 'lib/hooks/data/usePaymentDetails';
import { UserContext } from 'lib/context/UserContext';

const Navbar: FC = () => {
	const router = useRouter();
	const { user } = useContext(UserContext);
	const { payment_details } = usePaymentDetails();
	const [showSearch, setShowSearch] = useState(true);
	const [needStripeConnect, setNeedStripeConnect] = useState(false);

	useEffect(() => {
		if (user?.type === 'customer') {
			if (router.pathname.includes('/explore')) {
				setShowSearch(false);
			} else {
				setShowSearch(true);
			}
		} else {
			setShowSearch(false);
		}
	}, [router, user]);

	useEffect(() => {
		if (payment_details && user && user.type === 'storefront') {
			setNeedStripeConnect(!payment_details.stripe_connect_account_id);
		}
	}, [payment_details, user]);

	if (!user) return null;

	return (
		<>
			<div className={styles.container}>
				<Link href='/'>OrderSimply</Link>
				<div className={styles.content}>
					{showSearch ? <NavbarSearch /> : null}

					<NavbarLinks />
				</div>
			</div>
			{needStripeConnect ? (
				<a
					className={styles.alert}
					href={`https://connect.stripe.com/express/oauth/authorize?redirect_uri=https://${window.location.host}/account/stripe-connect&client_id=${process.env.NEXT_PUBLIC_STRIPE_CLIENT_ID}&state=${payment_details.stripe_state}&suggested_capabilities[]=card_payments`}>
					<WarningRounded fontSize='large' />
					<span>
						<strong>Setup Stripe Connect. </strong>
						Your products will not be available for bars until this step is complete
					</span>
				</a>
			) : null}
		</>
	);
};

export default Navbar;
