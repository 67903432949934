import { FC } from 'react';
import Link from 'next/link';
import {
	Phone,
	Email,
	Facebook,
	Instagram,
	QuestionAnswer,
	LibraryBooksRounded,
	Info,
	MenuBookRounded,
	LinkedIn,
} from '@mui/icons-material';
import styles from 'styles/components/Footer.module.scss';

const year = new Date().getFullYear();

const Footer: FC = () => {
	return (
		<div className={styles.container}>
			<div className={styles.content}>
				<div>
					<Link href='/'>
						<a className={styles.logo}>OrderSimply</a>
					</Link>
					<div className={styles.socials}>
						{/* <a href='https://twitter.com'>
							<Twitter />
						</a> */}
						<a href='https://www.facebook.com/OrderSimply-104496537988467/'>
							<Facebook />
						</a>
						<a href='https://www.instagram.com/order.simply/'>
							<Instagram />
						</a>
						<a href='https://www.linkedin.com/company/ordersimply/'>
							<LinkedIn />
						</a>
					</div>
					<span className={styles.fine}>copyright © {year} OrderSimply</span>
				</div>

				<div className={styles.links}>
					<Link href='/faq'>
						<a className={styles.link}>
							<QuestionAnswer />
							FAQs
						</a>
					</Link>
					<Link href='/blog'>
						<a className={styles.link}>
							<LibraryBooksRounded />
							Blog
						</a>
					</Link>
					<Link href='/about'>
						<a className={styles.link}>
							<Info />
							About
						</a>
					</Link>
					<Link href='/terms'>
						<a className={styles.link}>
							<MenuBookRounded />
							Terms
						</a>
					</Link>
				</div>

				<div className={styles.contact}>
					<h4>Contact Us</h4>
					<a href='tel:' className={styles.contactLink}>
						<Phone />
						<span>+1-(647)-338-2044</span>
					</a>
					<a href='mailto:support@ordersimply.ca' className={styles.contactLink}>
						<Email />
						<span>support@ordersimply.ca</span>
					</a>
				</div>
			</div>
		</div>
	);
};

export default Footer;
