import { useState } from 'react';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import styles from 'styles/components/NavbarSearch.module.scss';

const NavbarSearch = () => {
	const router = useRouter();
	const [search, setSearch] = useState('');
	const [searchSubmitted, setSearchSubmitted] = useState(false);

	const handleSearchInput = (e: any) => {
		setSearch(e.target.value);
	};

	useEffect(() => {
		document.querySelector('#nav_search')?.addEventListener('keypress', (e: any) => {
			if (e.key === 'Enter') {
				setSearchSubmitted(true);
			}
		});
	}, []);

	if (searchSubmitted) {
		router.push(`/explore?search=${search}`);
	}
	return (
		<input
			id='nav_search'
			className={styles.input}
			placeholder='search products'
			onChange={handleSearchInput}
		/>
	);
};

export default NavbarSearch;
