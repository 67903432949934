import useSWR from 'swr';
import { fetcher } from 'lib/fetcher';
import { user_payment_detail } from '@prisma/client';

const usePaymentDetails = () => {
	const { data, error } = useSWR('/api/me/payment-details', fetcher);

	return {
		payment_details: data as user_payment_detail,
		isLoading: !error && !data,
		isError: error,
	};
};

export default usePaymentDetails;
