import { useContext, useState, FC } from 'react';
import Link from 'next/link';
import moment from 'moment';
import { UserContext } from 'lib/context/UserContext';
import {
	ReceiptRounded,
	LocalShippingRounded,
	HelpRounded,
	CheckCircleRounded,
	HighlightOffRounded,
	ErrorRounded,
	AutorenewRounded,
	MessageRounded,
} from '@mui/icons-material';
import { notification } from '@prisma/client';
import styles from 'styles/components/Notification.module.scss';
import axios from 'axios';
import { mutate } from 'swr';
import { logError } from 'lib/functions/errors';

const NotificationIcon: FC<{ type: string | null }> = ({ type }) => {
	if (type === 'order') {
		return <LocalShippingRounded fontSize='large' />;
	}
	if (type === 'order_accepted') {
		return <CheckCircleRounded fontSize='large' />;
	}
	if (type === 'order_declined') {
		return <HighlightOffRounded fontSize='large' />;
	}
	if (type === 'order_disputed') {
		return <ErrorRounded fontSize='large' />;
	}
	if (type === 'order_changed') {
		return <AutorenewRounded fontSize='large' />;
	}
	if (type === 'order_message') {
		return <MessageRounded fontSize='large' />;
	}
	if (type === 'receipt') {
		return <ReceiptRounded fontSize='large' />;
	}
	return <HelpRounded fontSize='large' />;
};

const Notification: FC<{
	notification: notification;
	accountHub?: boolean;
}> = ({ notification, accountHub = false }) => {
	const date = moment(notification.created).fromNow();
	const read = notification.read;

	const markRead = (cta: string | null) => {
		if (cta) {
			axios
				.post(`/api/me/notifications/mark-read`, { cta })
				.then(() => {
					mutate('/api/me/notifications');
				})
				.catch((e) => {
					logError(e);
				});
		}
	};

	return (
		<div
			className={read ? styles.read : styles.container}
			style={accountHub ? { width: '100%' } : {}}
			onClick={() => {
				markRead(notification.cta);
			}}>
			<Link href={notification.cta || '/'}>
				<a className='basicFlex'>
					<div style={{ marginLeft: '10px' }}>
						<NotificationIcon type={notification.type} />
					</div>
					<div>
						<h3>{notification.text}</h3>
						<span>{date}</span>
					</div>
				</a>
			</Link>
		</div>
	);
};

export default Notification;
