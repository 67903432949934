import React, { useContext, useState } from 'react';
import Link from 'next/link';
import { UserContext } from 'lib/context/UserContext';
import {
	Receipt,
	ExitToAppRounded,
	AccountBalanceRounded,
	Settings,
	MenuRounded,
	ContactSupport,
	ListAltRounded,
	LocalShippingRounded,
	PhoneRounded,
	ShoppingCart,
	SupervisorAccountRounded,
	NotificationsRounded,
	HomeRounded,
	SearchRounded,
} from '@mui/icons-material';
import { Menu, MenuItem, ListItemIcon, SwipeableDrawer, Divider, Tooltip } from '@mui/material';
import Notification from 'components/Notifications/Notification';
import { UserContextType } from 'types';
import styles from 'styles/components/NavbarLinks.module.scss';
import useSWR, { mutate } from 'swr';
import { fetcher } from 'lib/fetcher';
import { notification } from '@prisma/client';
import { useEffect } from 'react';
import { Customer } from 'types/Customer';

const NavbarLinks = () => {
	const { signOut, user } = useContext(UserContext) as UserContextType;
	const [menuOpen, setMenuOpen] = useState(false);
	const [anchorElement, setAnchorElement] = useState(null);
	const [orderNotifications, setOrderNotifications] = useState(false);
	const [showNotifications, setShowNotifications] = useState(false);
	const [notificationAnchorElement, setNotificationAnchorElement] = useState(null);

	const { data } = useSWR('/api/me/notifications', fetcher);
	const { notifications, unread_count } = data ?? {};

	useEffect(() => {
		mutate('/api/me/notifications');
	}, []);

	// check if the user is on a iOS device in order to work properly with menu drawer
	const iOS = (process as any).browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

	const openUserMenu = (e: any) => {
		setAnchorElement(e.currentTarget);
	};

	const handleClose = () => {
		setAnchorElement(null);
	};

	const toggleMobileMenu = () => {
		setMenuOpen(!menuOpen);
	};

	const MobileMenu = () => {
		return (
			<>
				<MenuItem onClick={signOut}>
					<ListItemIcon>
						<ExitToAppRounded className={styles.menuItemIcon} />
					</ListItemIcon>
					<span className={styles.menuItemText}>Sign Out</span>
				</MenuItem>
				<Divider />
				<Link href='/'>
					<MenuItem onClick={toggleMobileMenu}>
						<ListItemIcon>
							<HomeRounded className={styles.menuItemIcon} />
						</ListItemIcon>
						<span className={styles.menuItemText}>Home</span>
					</MenuItem>
				</Link>
				<Link href='/account/overview'>
					<MenuItem onClick={toggleMobileMenu}>
						<ListItemIcon>
							<AccountBalanceRounded className={styles.menuItemIcon} />
						</ListItemIcon>
						<span className={styles.menuItemText}>My Account</span>
					</MenuItem>
				</Link>
				{user?.type === 'storefront' ? (
					<Link href='/products'>
						<MenuItem onClick={toggleMobileMenu}>
							<ListItemIcon>
								<ListAltRounded className={styles.menuItemIcon} />
							</ListItemIcon>
							<span className={styles.menuItemText}>My Products</span>
						</MenuItem>
					</Link>
				) : null}
				{user?.type === 'customer' ? (
					<Link href='/explore'>
						<MenuItem onClick={toggleMobileMenu}>
							<ListItemIcon>
								<SearchRounded className={styles.menuItemIcon} />
							</ListItemIcon>
							<span className={styles.menuItemText}>Explore</span>
						</MenuItem>
					</Link>
				) : null}
				<Link href='/settings'>
					<MenuItem onClick={toggleMobileMenu}>
						<ListItemIcon>
							<Settings className={styles.menuItemIcon} />
						</ListItemIcon>
						<span className={styles.menuItemText}>Settings</span>
					</MenuItem>
				</Link>
				<Divider />
				<Link href='orders'>
					<MenuItem onClick={toggleMobileMenu}>
						<ListItemIcon>
							<Receipt className={styles.menuItemIcon} />
						</ListItemIcon>
						<span className={styles.menuItemText}>
							{user?.type === 'storefront' ? 'Orders' : 'Orders & Receipts'}
						</span>
					</MenuItem>
				</Link>
				<Link href='/support'>
					<MenuItem onClick={toggleMobileMenu}>
						<ListItemIcon>
							<ContactSupport className={styles.menuItemIcon} />
						</ListItemIcon>
						<span className={styles.menuItemText}>Customer Support</span>
					</MenuItem>
				</Link>
				<Divider />
				<Link href='tel:16473382044'>
					<MenuItem>
						<ListItemIcon>
							<PhoneRounded className={styles.menuItemIcon} />
						</ListItemIcon>
						<span className={styles.menuItemText}>Call Us! +1-647 338-2044</span>
					</MenuItem>
				</Link>
			</>
		);
	};

	const NotificationAlert = ({ show = false, number = 0 }) => {
		if (show && number) {
			return <span className={styles.notification}>{`(${number})`}</span>;
		}
		if (show) {
			return <span className={styles.notification}>!</span>;
		}
		return null;
	};

	return (
		<div className={styles.container}>
			<MenuRounded className={styles.menuToggle} onClick={toggleMobileMenu} />
			<SwipeableDrawer
				anchor={'top'}
				open={menuOpen}
				onClose={toggleMobileMenu}
				onOpen={toggleMobileMenu}
				disableBackdropTransition={!iOS}
				disableDiscovery={iOS}>
				<MobileMenu />
			</SwipeableDrawer>
			{user?.type === 'storefront' ? (
				<Link href='/products'>
					<Tooltip title='My Products' placement='bottom'>
						<div className={styles.link}>
							<ListAltRounded />
						</div>
					</Tooltip>
				</Link>
			) : null}
			{user?.type === 'customer' ? (
				<Link href='/cart'>
					<Tooltip title='My Cart' placement='bottom'>
						<div className={styles.link}>
							<NotificationAlert
								show={(user?.profile as Customer)?.cart.length > 0}
							/>
							<ShoppingCart />
						</div>
					</Tooltip>
				</Link>
			) : null}
			{user?.type === 'customer' || user?.type === 'storefront' ? (
				<Link href='/orders'>
					<Tooltip title='Orders' placement='bottom'>
						<div className={styles.link}>
							<NotificationAlert show={orderNotifications} />
							{user?.type === 'storefront' && <LocalShippingRounded />}
							{user?.type === 'customer' && <Receipt />}
						</div>
					</Tooltip>
				</Link>
			) : null}
			{user?.admin && (
				<Link href='/admin'>
					<Tooltip title='Admin' placement='bottom'>
						<div className={styles.link}>
							<SupervisorAccountRounded />
						</div>
					</Tooltip>
				</Link>
			)}

			<div
				className={styles.link}
				onClick={(e: any) => {
					setShowNotifications(!showNotifications);
					setNotificationAnchorElement(e.currentTarget);
				}}>
				<NotificationAlert show={!!unread_count} number={unread_count} />
				<NotificationsRounded />
				<Menu
					id='customized-menu'
					anchorEl={notificationAnchorElement}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
					keepMounted
					open={showNotifications}
					onClose={() => setShowNotifications(false)}>
					<div>
						{notifications &&
							notifications.map((notification: notification) => {
								return (
									<Notification
										notification={notification}
										key={notification.id}
									/>
								);
							})}
					</div>
				</Menu>
			</div>
			<div>
				<div className={styles.link} onClick={openUserMenu}>
					<NotificationAlert show={false} />
					<MenuRounded />
				</div>
				<Menu
					id='customized-menu'
					anchorEl={anchorElement}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
					keepMounted
					open={Boolean(anchorElement)}
					onClose={handleClose}>
					<Link href='/account/overview'>
						<MenuItem onClick={handleClose}>
							<ListItemIcon>
								<AccountBalanceRounded className={styles.menuItemIcon} />
							</ListItemIcon>
							<span className={styles.menuItemText}>My Account</span>
						</MenuItem>
					</Link>
					<Link href='/settings'>
						<MenuItem onClick={handleClose}>
							<ListItemIcon>
								<Settings className={styles.menuItemIcon} />
							</ListItemIcon>
							<span className={styles.menuItemText}>Settings</span>
						</MenuItem>
					</Link>
					<Link href='/support'>
						<MenuItem onClick={handleClose}>
							<ListItemIcon>
								<ContactSupport className={styles.menuItemIcon} />
							</ListItemIcon>
							<span className={styles.menuItemText}>Customer Support</span>
						</MenuItem>
					</Link>
					<Divider />
					<MenuItem onClick={signOut}>
						<ListItemIcon>
							<ExitToAppRounded className={styles.menuItemIcon} />
						</ListItemIcon>
						<span className={styles.menuItemText}>Sign Out</span>
					</MenuItem>
				</Menu>
			</div>
		</div>
	);
};

export default NavbarLinks;
