import { FC, ReactNode } from 'react';
import PageTitle from './PageTitle';
import Footer from 'components/Footer';
import Navbar from 'components/Navbar';
import useScrollToTop from 'lib/hooks/useScrollToTop';

const Page: FC<{
	children: ReactNode;
	className?: string;
	title?: string;
	fade?: boolean;
	hideNavbar?: boolean;
	hideFooter?: boolean;
	padding?: string;
}> = ({
	children,
	className = '',
	title = null,
	fade,
	hideFooter = false,
	hideNavbar = false,
	padding = 'none',
}) => {
	useScrollToTop();
	return (
		<div>
			{title && <PageTitle title={title} />}
			{!hideNavbar && <Navbar />}
			<div style={{ padding }} className={`${className} ${fade ? ' fade-in' : ''}`}>
				{children}
			</div>
			{!hideFooter && <Footer />}
		</div>
	);
};

export default Page;
